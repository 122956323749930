@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 266.1 100 63.9;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 266.1 100 63.9 29%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 266.1 100% 63.9%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 224.3 76.3% 48%;
    --sec-started: 54.2 95.3% 74.5% / 30%;
    --sec-started-hovered: 266.1 100 63.9 / 20%;
    --sec-started-foreground: 210 40% 98%;
    --sec-not-started: 0 67.5% 43.1% / 30%;
    --sec-not-started-hovered: 0 67.5% 43.1% / 20%;
    --sec-not-started-foreground: 210 40% 98%;
    --sec-success: 134.5 63.3% 34.1% / 30%;
    --sec-success-hovered: 134.5 63.3% 34.1% / 20%;
    --sec-success-foreground: 210 40% 98%;
    --koi: #3b82f6;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply dark bg-background text-foreground;
  }
}

/*<uniquifier>: Use a unique and descriptive class name*/
/*<weight>: Use a value from 200 to 800*/

/* .dosis-fontCss {
  font-family: 'Dosis', sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
} */

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  display: none;
}
.manrope-fontCss {
  font-family: 'Manrope', sans-serif;
  font-optical-sizing: auto;

  font-style: normal;
}

@keyframes torch-highlight {
  0% {
    border-bottom-color: transparent;
    box-shadow:
      0 0 0 rgba(0, 0, 0, 0),
      0 0 0 rgba(0, 0, 0, 0);
    background: transparent;
  }

  50% {
    border-bottom-color: #8b5cf6;
    /* Midpoint color */
    box-shadow:
      0 4px 8px rgba(0, 0, 0, 0.3),
      0 0 10px rgba(0, 0, 0, 0.2);
    background: linear-gradient(transparent, rgba(139, 92, 246, 0.2));
  }

  100% {
    border-bottom-color: #8b5cf6;
    /* Final color */
    box-shadow:
      0 8px 16px rgba(0, 0, 0, 0.4),
      0 0 15px rgba(0, 0, 0, 0.3);
    background: linear-gradient(transparent, rgba(139, 92, 246, 0.4));
  }
}
.gradient-border {
  position: relative;
  background: linear-gradient(90deg, #feb101, #ffffff);
  padding: 2px;
}

.gradient-border::before {
  content: '';
  position: absolute;
  margin: 1px;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.gradient-border > * {
  position: relative;
}

.torch-highlight {
  animation: torch-highlight 0.6s ease-in-out;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #333;
}
main {
  @apply h-screen relative;
}
footer {
  @apply absolute bottom-20 left-1/2 -translate-x-1/2 w-full p-4;
}


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

input[type=number] {
  -moz-appearance: textfield;
}