.accordion-content {
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  max-height: 0;
  opacity: 0;
}

[data-state="open"] .accordion-content {
  max-height: 500px;
  opacity: 1;
}